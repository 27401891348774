import React, { useContext, useState } from 'react'
import { JSBI, Pair, Percent } from '@tooldex/sdk'
import { ContextProviderWrapper } from 'components/Context'
import { FormMask } from 'Layout/styled'
import { ChevronDown, ChevronUp } from 'react-feather'
import CardBody from 'components/Card/CardBody'
import { Text } from 'components/Text'
import { Button } from 'components/Button'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useAccount } from 'wagmi'
import Value from 'components/Value'
import { useTotalSupply } from '../../data/TotalSupply'
import { useTokenBalance } from '../../state/wallet/hooks'
import { currencyId } from '../../utils/currencyId'
import { unwrappedToken } from '../../utils/wrappedCurrency'
import Card from '../Card'
import { AutoColumn } from '../Column'
import CurrencyLogo from '../CurrencyLogo'
import DoubleCurrencyLogo from '../DoubleLogo'
import { RowBetween, RowFixed } from '../Row'
import { Dots } from '../swap/styleds'

export const FixedHeightRow = styled(RowBetween)`
  height: 24px;
`

export const HoverCard = styled(Card)`
  border: 1.5px solid rgba(226, 226, 226, 1);
`

interface PositionCardProps {
  pair: Pair
  // eslint-disable-next-line react/no-unused-prop-types
  showUnwrapped?: boolean
}

export function MinimalPositionCard({ pair, showUnwrapped = false }: PositionCardProps) {
  const { address } = useAccount()
  const currency0 = showUnwrapped ? pair.token0 : unwrappedToken(pair.token0)
  const currency1 = showUnwrapped ? pair.token1 : unwrappedToken(pair.token1)

  const [showMore, setShowMore] = useState(false)

  const userPoolBalance = useTokenBalance(address ?? undefined, pair.liquidityToken)
  const totalPoolTokens = useTotalSupply(pair.liquidityToken)

  const [token0Deposited, token1Deposited] =
    !!pair &&
      !!totalPoolTokens &&
      !!userPoolBalance &&
      // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
      JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? [
        pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false),
        pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false),
      ]
      : [undefined, undefined]

  const { isMobile } = useContext(ContextProviderWrapper)!

  return (
    <>
      {userPoolBalance && (
        <div style={{ border: '1.5px solid #e2e2e2', borderRadius: '20px', background: 'rgb(227, 235, 255)' }}>
          <CardBody style={{ paddingTop: '20px', paddingBottom: '10px' }}>
            <AutoColumn>
              <FixedHeightRow style={{ marginBottom: isMobile ? "0" : "-10px" }}>
                <RowFixed>
                  <Text style={{ fontSize: "14px" }} color="textSubtle">
                    LP Tokens in your Wallet
                  </Text>
                </RowFixed>
              </FixedHeightRow>
              <FormMask style={{ paddingTop: '30px', paddingBottom: '10px', backgroundImage: `url("/images/bunny/Form/form_mask_modal_2.png")` }}>
                <FixedHeightRow style={{ marginBottom: '5px' }} onClick={() => setShowMore(!showMore)}>
                  <RowFixed>
                    <DoubleCurrencyLogo currency0={currency0} currency1={currency1} margin size={20} />
                    <Text fontSize="14px">
                      {currency0.symbol}/{currency1.symbol}
                    </Text>
                  </RowFixed>
                  <RowFixed>
                    <Text >
                      <Value
                        size={14}
                        value={Number(userPoolBalance.toSignificant(4))}
                      />
                    </Text>

                  </RowFixed>
                </FixedHeightRow>
                <AutoColumn gap="4px">
                  <FixedHeightRow >
                    <Text fontSize="14px">{currency0.symbol}:</Text>
                    {token0Deposited ? (
                      <RowFixed>
                        <Text ml="6px">
                          <Value
                            size={14}
                            value={Number(token0Deposited.toSignificant(6))}
                          />
                        </Text>
                      </RowFixed>
                    ) : (
                      '-'
                    )}
                  </FixedHeightRow>
                  <FixedHeightRow>
                    <Text fontSize="14px">{currency1.symbol}:</Text>
                    {token1Deposited ? (
                      <RowFixed>
                        <Text ml="6px" >
                          <Value
                            size={14}
                            value={Number(token1Deposited.toSignificant(6))}
                          />
                        </Text>
                      </RowFixed>
                    ) : (
                      '-'
                    )}
                  </FixedHeightRow>
                </AutoColumn>
              </FormMask>
            </AutoColumn>
          </CardBody>
        </div>
      )}
    </>
  )
}

export default function FullPositionCard({ pair }: PositionCardProps) {
  const { address } = useAccount()

  const currency0 = unwrappedToken(pair.token0)
  const currency1 = unwrappedToken(pair.token1)

  const [showMore, setShowMore] = useState(false)

  const userPoolBalance = useTokenBalance(address ?? undefined, pair.liquidityToken)
  const totalPoolTokens = useTotalSupply(pair.liquidityToken)

  const poolTokenPercentage =
    !!userPoolBalance && !!totalPoolTokens && JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? new Percent(userPoolBalance.raw, totalPoolTokens.raw)
      : undefined

  const [token0Deposited, token1Deposited] =
    !!pair &&
      !!totalPoolTokens &&
      !!userPoolBalance &&
      // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
      JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? [
        pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false),
        pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false),
      ]
      : [undefined, undefined]

  const { isMobile } = useContext(ContextProviderWrapper)!

  return (
    <HoverCard style={{ padding: "15px 20px" }}>
      <AutoColumn gap="12px">
        <FixedHeightRow onClick={() => setShowMore(!showMore)} style={{ cursor: 'pointer' }}>
          <RowFixed>
            <DoubleCurrencyLogo currency0={currency0} currency1={currency1} margin size={20} />
            <Text>{!currency0 || !currency1 ? <Dots>Loading</Dots> : `${currency0.symbol}/${currency1.symbol}`}</Text>
          </RowFixed>
          <RowFixed>
            {showMore ? (
              <ChevronUp color="#000" size="20" style={{ marginLeft: '10px' }} />
            ) : (
              <ChevronDown color="#000" size="20" style={{ marginLeft: '10px' }} />
            )}
          </RowFixed>
        </FixedHeightRow>
        {showMore && (
          <AutoColumn gap="8px">
            <FixedHeightRow>
              <RowFixed>
                <Text>Pooled {currency0.symbol}:</Text>
              </RowFixed>
              {token0Deposited ? (
                <RowFixed>
                  <Text ml="6px">
                    <Value
                      size={14}
                      value={Number(token0Deposited?.toSignificant(6))}
                    />
                  </Text>
                  <CurrencyLogo size="20px" style={{ marginLeft: '8px' }} currency={currency0} />
                </RowFixed>
              ) : (
                '-'
              )}
            </FixedHeightRow>
            <FixedHeightRow>
              <RowFixed>
                <Text>Pooled {currency1.symbol}:</Text>
              </RowFixed>
              {token1Deposited ? (
                <RowFixed>
                  <Text>
                    <Value
                      size={14}
                      value={Number(token1Deposited?.toSignificant(6))}
                    />
                  </Text>
                  <CurrencyLogo size="20px" style={{ marginLeft: '8px' }} currency={currency1} />
                </RowFixed>
              ) : (
                '-'
              )}
            </FixedHeightRow>
            <FixedHeightRow>
              <Text>Your pool tokens:</Text>
              <Text>
                <Value
                  size={14}
                  value={Number(userPoolBalance?.toSignificant(4))}
                />
              </Text>

            </FixedHeightRow>
            <FixedHeightRow>
              <Text>Your pool share:</Text>
              <Text style={{
                color: "#004CCD"
              }}>{poolTokenPercentage ? `${poolTokenPercentage.toFixed(2)}%` : '-'}</Text>
            </FixedHeightRow>
            <RowBetween marginTop="10px">
              <Button
                as={Link}
                to={`/v2/add/${currencyId(currency0)}/${currencyId(currency1)}`}
                style={{ width: '48%' }}
              >
                Add
              </Button>
              <Button
                as={Link}
                style={{ width: '48%' }}
                to={`/v2/remove/${currencyId(currency0)}/${currencyId(currency1)}`}
              >
                Remove
              </Button>
            </RowBetween>
          </AutoColumn>
        )}
      </AutoColumn>
    </HoverCard>
  )
}
