import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Input } from "antd";
import { InputContainer } from "./styled";

const InputCommon = ({
  value,
  addonBefore,
  addonAfter,
  defaultValue,
  placeHolder,
  onChange,
  password,
  disabled,
  suffix,
  prefix,
  type
}: any) => {
  return (
    <InputContainer>
      {password ? (
        <>
          <Input.Password
            disabled={disabled}
            defaultValue={defaultValue}
            placeholder={placeHolder}
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
        </>
      ) : (
        <>
          <Input
            className={addonBefore && addonAfter ? "addon-input" : ""}
            addonBefore={addonBefore}
            addonAfter={addonAfter}
            value={value}
            prefix={prefix}
            disabled={disabled}
            defaultValue={defaultValue}
            placeholder={placeHolder}
            onChange={onChange}
            suffix={suffix}
            type={type}
          />
        </>
      )}
    </InputContainer>
  );
};

export default InputCommon;
