import styled from 'styled-components'
import { InputProps, scales } from './types'

const Input = styled.input<InputProps>`
  border-radius: 10px;
  border: 1px solid #CAD2D9;
  background: #FFF;
  color: #000;
  display: block;
  font-size: 16px;
  outline: 0;
  padding: 10px;
  width: 100%;
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
  &::placeholder {
    color: ${({ theme }) => theme.colors.textSubtle};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.backgroundDisabled};
    box-shadow: none;
    color: ${({ theme }) => theme.colors.textDisabled};
    cursor: not-allowed;
  }
`

Input.defaultProps = {
  scale: scales.MD,
  isSuccess: false,
  isWarning: false,
}

export default Input
