import React from 'react'
import { ChainId } from '@tooldex/sdk'
import { LinkExternal } from 'components/Link'
import { Button } from 'components/Button'
import {
  CheckCircleOutlined
} from '@ant-design/icons';
import { AutoColumn } from '../Column'
import { getBscScanLink } from '../../utils'
import { Wrapper, Section, ConfirmedIcon, ContentHeader } from './helpers'
import { Text } from '../Text'

type TransactionSubmittedContentProps = {
  onDismiss: () => void
  hash: string | undefined
  chainId: ChainId
}

const TransactionSubmittedContent = ({ onDismiss, chainId, hash }: TransactionSubmittedContentProps) => {

  return (
    <Wrapper>
      <Section>
        <ContentHeader onDismiss={onDismiss}>Transaction submitted</ContentHeader>
        <ConfirmedIcon style={{ padding: '40px 0 20px' }}>
          <img src="../images/success.png" alt="icon" style={{width:'80px'}} />
        </ConfirmedIcon>
        <AutoColumn justify="center">
          {chainId && hash && (
            <LinkExternal href={getBscScanLink(chainId, hash, 'transaction')}>View on Bscscan</LinkExternal>
          )}
          <Text fontSize="14px">Please waiting for a moment</Text>
        </AutoColumn>
      </Section>
    </Wrapper>
  )
}

export default TransactionSubmittedContent
