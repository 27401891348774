import styled from "styled-components";

export const FormConfirmContainer = styled.div`
  margin-top: 10px;
  width: 100%;
`
export const FormConfirmTokenName = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
  border-radius: 8px;
  background: #EFF9F9;
  overflow: hidden;
  margin-bottom: 10px;
  &::before,
  &::after {
    content: "";
    position: absolute;
    background: url("/images/addToken/create_token_decor.png") no-repeat center / 100% auto;
    width: 68px;
    height: 64px;
    top: 0;
  }
  &::before {
    left: 0;
  }
  &::after {
    right: 0;
  }
  & > p {
    &:nth-child(1) {
      color: #0D0C43;
      font-family: Outfit-Medium;
      font-size: 14px;
    }
    &:nth-child(2) {
      color: #00B2C8;
      font-family: Outfit-Bold;
      font-size: 15px;
    }
  }
`
export const FormConfirmRow = styled.div`
  border-radius: 8px;
  background: #F5F7FF;
  padding: 6px 10px;
  margin-bottom: 10px;
`
export const ConfirmRowBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:not(:last-child) {
    margin-bottom: 3px;
  }
  & > p {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #0D0C43;
    font-size: 14px;
    &.color {
      color: #00B2C8;
    }
    &.bold {
      color: #000;
      font-size: 14px;
    }
  }
  @media screen and (max-width: 767px) {
    & > p {
      &.bold {
        font-size: 12px;
      }
    }
  }
`
export const FormConfirmReview = styled.div`
  padding-top: 10px;
  border-top: 1px solid rgba(84, 132, 255, 0.25);
  margin-bottom: 50px;
  h2 {
    color: #0D0C43;
    font-size: 14px;
    letter-spacing: 0.14px;
    margin-bottom: 10px;
  }
`
export const ConfirmReviewBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  border-radius: 12px;
  border: 1px solid #273DFF;
  background: #F5F7FF;
`
export const ReviewLeft = styled.div`
  & > p {
    &:nth-child(1) {
      color: #0D0C43;
      font-family: Outfit-Medium;
      font-size: 14px;
    }
    &:nth-child(2) {
      color: #717696;
      font-size: 12px;
    }
  }
`
export const ReviewRight = styled.div`
  & > p {
    color: #5771FF;
    text-align: right;
    &:nth-child(1) {
      font-size: 12px;
    }
    &:nth-child(2) {
      color: #5771FF;
      font-family: Outfit-Medium;
      font-size: 16px;
    }
  }
`