import React, { useMemo } from 'react'
import { Pair } from '@tooldex/sdk'
import { Link } from 'react-router-dom'
import { FormMask } from 'Layout/styled'
import CardNav from 'components/CardNav'
import FullPositionCard from 'components/PositionCard'
import { useAccount } from 'wagmi'
import { useTokenBalancesWithLoadingIndicator } from 'state/wallet/hooks'
import { StyledInternalLink } from 'components/Shared'
import { LightCard } from 'components/Card'
import { RowBetween } from 'components/Row'
import { AutoColumn } from 'components/Column'
import { Text } from 'components/Text'
import CardBody from 'components/Card/CardBody'
import { Button } from 'components/Button'
import { usePairs } from 'data/Reserves'
import { toV2LiquidityToken, useTrackedTokenPairs } from 'state/user/hooks'
import { Dots } from 'components/swap/styleds'
import TranslatedText from 'components/TranslatedText'
import { TranslateString } from 'utils/translateTextHelpers'
import PageHeader from 'components/PageHeader'
import AppBody from '../AppBody'

export default function Pool() {
  const { address } = useAccount()

  // fetch the user's balances of all tracked V2 LP tokens
  const trackedTokenPairs = useTrackedTokenPairs()
  const tokenPairsWithLiquidityTokens = useMemo(
    () => trackedTokenPairs.map((tokens) => ({ liquidityToken: toV2LiquidityToken(tokens), tokens })),
    [trackedTokenPairs]
  )
  const liquidityTokens = useMemo(
    () => tokenPairsWithLiquidityTokens.map((tpwlt) => tpwlt.liquidityToken),
    [tokenPairsWithLiquidityTokens]
  )
  const [v2PairsBalances, fetchingV2PairBalances] = useTokenBalancesWithLoadingIndicator(
    address ?? undefined,
    liquidityTokens
  )

  // fetch the reserves for all V2 pools in which the user has a balance
  const liquidityTokensWithBalances = useMemo(
    () =>
      tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
        v2PairsBalances[liquidityToken.address]?.greaterThan('0')
      ),
    [tokenPairsWithLiquidityTokens, v2PairsBalances]
  )

  const v2Pairs = usePairs(liquidityTokensWithBalances.map(({ tokens }) => tokens))
  const v2IsLoading =
    fetchingV2PairBalances || v2Pairs?.length < liquidityTokensWithBalances.length || v2Pairs?.some((V2Pair) => !V2Pair)

  const allV2PairsWithLiquidity = v2Pairs.map(([, pair]) => pair).filter((v2Pair): v2Pair is Pair => Boolean(v2Pair))

  return (
    <>
      <CardNav activeIndex={1} />
      <AppBody>
        <PageHeader title="Liquidity" description="Add liquidity to receive LP tokens" isSettings />
        <FormMask style={{ paddingLeft: '40px', paddingRight: "40px" }}>
          <AutoColumn gap="lg" justify="center">
            <CardBody style={{
              paddingLeft: 0,
              paddingRight: 0
            }}>
              <AutoColumn gap="12px" style={{ width: '100%' }}>
                <RowBetween padding="0 8px">
                  <Text color="#fac668">
                    <TranslatedText translationId={102}>Your Liquidity</TranslatedText>
                  </Text>
                </RowBetween>
                {!address ? (
                  <LightCard padding="40px 20px">
                    <Text color="#fac668" textAlign="center">
                      Connect to a wallet to view your liquidity.
                    </Text>
                  </LightCard>
                ) : v2IsLoading ? (
                  <LightCard padding="40px 20px">
                    <Text color="#fac668" textAlign="center">
                      <Dots>Loading</Dots>
                    </Text>
                  </LightCard>
                ) : allV2PairsWithLiquidity?.length > 0 ? (
                  <>
                    {allV2PairsWithLiquidity.map((v2Pair) => (
                      <FullPositionCard key={v2Pair.liquidityToken.address} pair={v2Pair} />
                    ))}
                  </>
                ) : (
                  <LightCard padding="40px 20px">
                    <Text color="#fac668" textAlign="center">
                      <TranslatedText translationId={104}>No liquidity found.</TranslatedText>
                    </Text>
                  </LightCard>
                )}
                <div>
                  <Link
                    style={{
                      display: 'block',
                      width: 'max-content',
                      marginTop: '5px',
                      textDecoration: 'underline',
                      textUnderlineOffset: '3px',
                    }}
                    to="/v2/find"
                  >
                    <Text
                      style={{
                        width: 'max-content',
                        fontSize: '16px',
                      }}
                    >
                      Find liquidity here
                    </Text>
                  </Link>
                  {/* <Text fontSize="14px" style={{ padding: '.5rem 0 .5rem 0' }}>
                  {TranslateString(106, "Don't see a pool you joined?")}{' '}
                  <StyledInternalLink id="import-pool-link" to="/find">
                    {TranslateString(108, 'Import it.')}
                  </StyledInternalLink>
                </Text> */}
                  <Text fontSize="14px" style={{ paddingTop: '.5rem' }}>
                    Or, if you staked your FLIP tokens in a farm, unstake them to see them here.
                  </Text>
                </div>
              </AutoColumn>
            </CardBody>
          </AutoColumn>
          <Button isNormal width="100%" id="join-pool-button" as={Link} to="/v2/add/BNB">
            <TranslatedText translationId={100}>Add Liquidity</TranslatedText>
          </Button>
        </FormMask>
      </AppBody>
    </>
  )
}
