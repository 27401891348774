/* eslint-disable import/prefer-default-export */
import { Flex } from "components/Box";
import styled from "styled-components";

export const RecentTransactionWrap = styled.div`
  margin-top: 10px;
  transition: all .15s linear;
  cursor: pointer;
  &:hover {
    background-color: #F5F7FF;
  }
  ${Flex} {
    & > div {
      transition: all .15s linear;
      &:nth-child(1) {
        &:hover {
          opacity: .5;
        }
      }
    }
  }
`
export const RecentTransactionList = styled.div`
  max-height: 300px;
  overflow-y: auto;
`