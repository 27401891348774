import React, { useContext, useEffect } from 'react'
import { AlertCircle, CheckCircle } from 'react-feather'
import { Text } from 'components/Text'
import styled, { ThemeContext } from 'styled-components'
import { AutoColumn } from '../Column'
import { AutoRow } from '../Row'

const RowNoFlex = styled(AutoRow)`
  flex-wrap: nowrap;
`
export default function TransactionPopup({
  hash,
  success,
  summary,
}: {
  hash: string
  success?: boolean
  summary?: string
}) {
  const theme = useContext(ThemeContext)

  return (
    <RowNoFlex>
      <div style={{ paddingRight: 16 }}>
        {success ? (
          <CheckCircle color='#717696' size={24} />
        ) : (
          <AlertCircle color={theme.colors.failure} size={24} />
        )}
      </div>
      <AutoColumn gap="0">
        <Text style={{ marginBottom: 0, fontSize: "16px",color: '#0D0C43' }}>{summary ?? `Hash: ${hash.slice(0, 8)}...${hash.slice(58, 65)}`}</Text>
        <a href={`https://bscscan.com/tx/${hash}`} style={{ display: 'flex', color: '#717696' }} target="_blank" rel="noreferrer">
          View on bscscan
        </a>
      </AutoColumn>
    </RowNoFlex>
  )
}
