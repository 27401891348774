import { useCallback, useEffect, useState } from 'react'
import walletClientToSigner from 'utils/walletClientToSigner'
import { getWeb3NoAccount } from 'utils/web3'
import { useDispatch } from 'react-redux'
import { useAccount, useBlockNumber, useWalletClient } from 'wagmi'
import useDebounce from '../../hooks/useDebounce'
import useIsWindowVisible from '../../hooks/useIsWindowVisible'
import { updateBlockNumber } from './actions'

export default function Updater(): null {
  const { chainId } = useAccount()
  const dispatch = useDispatch()
  const { data: walletClient } = useWalletClient({ chainId: 56 })
  const library = walletClient ? walletClientToSigner(walletClient, chainId).provider : getWeb3NoAccount()
  const windowVisible = useIsWindowVisible()

  const [state, setState] = useState<{ chainId: number | undefined; blockNumber: number | null }>({
    chainId,
    blockNumber: null,
  })

  const blockNumberCallback = useCallback(
    (blockNumber: number) => {
      setState((s) => {
        if (chainId === s.chainId) {
          if (typeof s.blockNumber !== 'number') return { chainId, blockNumber }
          return { chainId, blockNumber: Math.max(blockNumber, s.blockNumber) }
        }
        return s
      })
    },
    [chainId, setState]
  )

  const { data: blockNumber } = useBlockNumber({ chainId })

  useEffect(() => {
    setState({ chainId: 56, blockNumber: null })

    blockNumberCallback(Number(blockNumber))
  }, [dispatch, chainId, blockNumber, blockNumberCallback])

  const debouncedState = useDebounce(state, 100)

  useEffect(() => {
    if (!debouncedState.blockNumber) return
    dispatch(updateBlockNumber({ chainId: 56, blockNumber: debouncedState.blockNumber }))
  }, [windowVisible, dispatch, library, debouncedState.blockNumber, debouncedState.chainId])

  return null
}
