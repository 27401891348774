import { providers } from 'ethers'

export function walletClientToSigner(walletClient: any, chainId = 56) {
  const { account, chain, transport } = walletClient
  const network = {
    chainId: chainId,
    name: chain?.name || 'unknown',
    ensAddress: chain?.contracts?.ensRegistry?.address
  }
  const provider = new providers.Web3Provider(transport, network)
  const signer = provider.getSigner(account.address)
  return { signer, provider }
}

export default walletClientToSigner
