import React from 'react'
import styled from 'styled-components'
import { Card } from '@bscstation/uikit'
import { FormMask } from 'Layout/styled'

export const BodyWrapper = styled(Card)`
  position: relative;
  max-width: 436px;
  width: 100%;
  height: 100%;
  z-index: 5;
  background: #EDF0FF;
  overflow: hidden;
  &::before {
    content: "";
    position: absolute;
    left: calc(50% - 20px);
    top: 0;
    width: 100%;
    height: 100%;
    margin-left: -168px;
    background: url(${"../images/bunny/Form/form_decor.png"}) no-repeat center /
      100% auto;
    pointer-events: none;
    z-index: -1;
  }
`
export default function AppBody({ children }: { children: React.ReactNode }) {
  return <BodyWrapper>{children}</BodyWrapper>
}
