/* eslint-disable no-return-assign */
/* eslint-disable react/react-in-jsx-scope */
import { useAccount } from 'wagmi'
import { useCurrencyBalance } from 'state/wallet/hooks'
import Value from 'components/Value'
import { BalanceBlock, BalanceTokenWrap } from './styled'

const BalanceToken = ({ fromToken, toToken, hideBalance = false, style }: any) => {
  const { address } = useAccount()
  const selectedCurrencyBalanceForm = useCurrencyBalance(address ?? undefined, fromToken ?? undefined)
  const selectedCurrencyBalanceTo = useCurrencyBalance(address ?? undefined, toToken ?? undefined)
  return (
    <BalanceTokenWrap style={style}>
      <BalanceBlock>
        <div>
          <figure>
            {fromToken?.tokenInfo?.logoURI ? (
              <img
                src={
                  fromToken?.tokenInfo?.logoURI ||
                  `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/assets/${fromToken?.address}/logo.png`
                }
                alt="icon"
                onError={(e) => (e.currentTarget.src = '/images/bunny/Coins/undefined_icon.svg')}
              />
            ) : (!fromToken?.tokenInfo?.logoURI && fromToken?.symbol === 'BNB') || fromToken?.symbol === 'WBNB' ? (
              <img
                src={
                  fromToken?.symbol === 'BNB'
                    ? '/images/bunny/Coins/bnb.png'
                    : `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/assets/${fromToken?.address}/logo.png`
                }
                alt="icon"
                onError={(e) => (e.currentTarget.src = '/images/bunny/Coins/undefined_icon.svg')}
              />
            ) : (
              <img src="/images/bunny/Coins/undefined_icon.svg" alt="icon" />
            )}
          </figure>
          <p>{fromToken?.symbol ? fromToken?.symbol : '--'}</p>
        </div>
        <p>
          {!hideBalance && !!fromToken && selectedCurrencyBalanceForm
            ? <Value
              color='#00b2c8'
              value={Number(selectedCurrencyBalanceForm?.toSignificant(6))}
            />
            : '--'}
        </p>
      </BalanceBlock>
      <BalanceBlock>
        <div>
          <figure>
            {toToken?.tokenInfo?.logoURI ? (
              <img
                src={
                  toToken?.tokenInfo?.logoURI ||
                  `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/assets/${toToken?.address}/logo.png`
                }
                alt="icon"
                onError={(e) => (e.currentTarget.src = '/images/bunny/Coins/undefined_icon.svg')}
              />
            ) : (!toToken?.tokenInfo?.logoURI && toToken?.symbol === 'BNB') || toToken?.symbol === 'WBNB' ? (
              <img
                src={
                  toToken?.symbol === 'BNB'
                    ? '/images/bunny/Coins/bnb.png'
                    : `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/assets/${toToken?.address}/logo.png`
                }
                alt="icon"
                onError={(e) => (e.currentTarget.src = '/images/bunny/Coins/undefined_icon.svg')}
              />
            ) : (
              <img src="/images/bunny/Coins/undefined_icon.svg" alt="icon" />
            )}
          </figure>
          <p>{toToken?.symbol ? toToken?.symbol : '--'}</p>
        </div>
        <p>
          {!hideBalance && !!toToken && selectedCurrencyBalanceTo
            ? <Value
              color='#00b2c8'
              value={Number(selectedCurrencyBalanceTo?.toSignificant(6))}
            />
            : '--'}
        </p>
      </BalanceBlock>
    </BalanceTokenWrap>
  )
}

export default BalanceToken
