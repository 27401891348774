import React, { Suspense, useEffect, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import 'react-notifications-component/dist/theme.css'
import { useAccount } from 'wagmi'
import { useWeb3React } from '@web3-react/core'
import { injected, walletconnect } from 'connectors'
import Popups from '../components/Popups'
import { RedirectDuplicateTokenIds, RedirectOldAddLiquidityPathStructure } from './AddLiquidity/redirects'
import { RedirectOldRemoveLiquidityPathStructure } from './RemoveLiquidity/redirects'
import AddLiquidity from './AddLiquidity'
import Pool from './Pool'
import PoolFinder from './PoolFinder'
import RemoveLiquidity from './RemoveLiquidity'
import Swap from './Swap'
import { RedirectPathToSwapOnly } from './Swap/redirects'
import { EN, allLanguages } from '../constants/localisation/languageCodes'
import { LanguageContext } from '../hooks/LanguageContext'
import { TranslationsContext } from '../hooks/TranslationsContext'
import Menu from '../components/Menu'
import 'bootstrap/dist/css/bootstrap.min.css'
import AddToken from './AddToken'

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
`
const BodyWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  flex: 1;
  overflow: hidden;
  z-index: 1;
  padding: 20px 10px;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: bottom 24px center;
  background-size: 90%;
  justify-content: flex-start !important;
  @media screen and (min-width: 768px) {
    min-height: 100vh;
    /* &::before,
    &::after {
      content: "";
      position: absolute;
      background: no-repeat center / 100% auto;
    }
    &::before {
      background-image: url('/images/bunny/mascot_1.png');
      width: 302px;
      height: 282px;
      left: 50%;
      bottom: 200px;
      margin-left: -600px;
    }
    &::after {
      background-image: url('/images/bunny/mascot_2.png');
      width: 156px;
      height: 314px;
      right: 50%;
      bottom: 150px;
      margin-right: -400px;
    } */
  }
`

export default function App() {
  const [selectedLanguage, setSelectedLanguage] = useState<any>(undefined)
  const [translatedLanguage, setTranslatedLanguage] = useState<any>(undefined)
  const [translations, setTranslations] = useState<Array<any>>([])

  const getStoredLang = (storedLangCode: string) => {
    return allLanguages.filter((language) => {
      return language.code === storedLangCode
    })[0]
  }

  useEffect(() => {
    const storedLangCode = localStorage.getItem('bscStationSwapLanguage')
    if (storedLangCode) {
      const storedLang = getStoredLang(storedLangCode)
      setSelectedLanguage(storedLang)
    } else {
      setSelectedLanguage(EN)
    }
  }, [])

  const { address } = useAccount()
  const { activate } = useWeb3React()

  useEffect(() => {
    const getConnector: any = localStorage.getItem('@w3m/connected_connector')?.toLowerCase().toString()
    if (address) {
      if (getConnector === 'walletconnect') {
        activate(walletconnect)
      } else {
        activate(injected)
      }
    }
  }, [address, activate])

  return (
    <Suspense fallback={null}>
      <AppWrapper>
        <LanguageContext.Provider
          value={{ selectedLanguage, setSelectedLanguage, translatedLanguage, setTranslatedLanguage }}
        >
          <TranslationsContext.Provider value={{ translations, setTranslations }}>
            <Menu>
              <div className="full-with">
                <BodyWrapper id="body-wrapper-bsc">
                  <Popups />
                  <Switch>
                    <Route exact path="/v2/swap" component={Swap} />
                    <Route exact path="/v2/createToken" component={AddToken} />
                    <Route exact strict path="/v2/find" component={PoolFinder} />
                    <Route exact strict path="/v2/pool" component={Pool} />
                    <Route exact path="/v2/add" component={AddLiquidity} />
                    <Route exact strict path="/v2/remove/:currencyIdA/:currencyIdB" component={RemoveLiquidity} />
                    <Route exact path="/v2/add/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
                    <Route exact path="/v2/add/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
                    <Route exact strict path="/v2/remove/:tokens" component={RedirectOldRemoveLiquidityPathStructure} />
                    <Route component={RedirectPathToSwapOnly} />
                  </Switch>
                </BodyWrapper>
              </div>
            </Menu>
          </TranslationsContext.Provider>
        </LanguageContext.Provider>
      </AppWrapper>
    </Suspense>
  )
}
