import { FormMask } from 'Layout/styled'
import StyledButton from 'components/Button/StyledButton'
import styled from 'styled-components'

export const HeaderLogo = styled.div`
  a {
    display: flex;
    align-items: center;
    gap: 10px;
    figure {
      width: 110px;
      height: 37px;
      img {
        width: 100%;
      }
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    a {
      figure {
        width: 105px;
        height: 36px;
      }
    }
  }
`
export const UserNav = styled.div`
  display: flex;
  gap: 20px;
  transition: all 0.15s linear;
  &:hover div a {
    opacity: 0.7;
  }
  & > div {
    a {
      color: #fff;
      font-family: Outfit-SemiBold;
      font-size: 16px;
      letter-spacing: 0.16px;
      transition: all 0.15s linear;
      &:hover,
      &.active-both,
      &.active {
        opacity: 1;
        color: #ffd100 !important;
      }
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    gap: 15px;
    & > div {
      a {
        font-size: 14px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
    & > div {
      a {
        font-family: Panton-Black;
        font-size: 20px;
      }
    }
  }
`
export const NavItemWrap = styled.div<{ Delay?: string }>`
  @media screen and (max-width: 767px) {
    &.open {
      animation: fadeNavItem ${({ Delay }) => Delay} cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
    @keyframes fadeNavItem {
      0% {
        transform: translateX(-100px);
        opacity: 0.2;
      }
      50% {
        opacity: 0.5;
      }
      100% {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
`
export const HeaderNav = styled.div`
  display: flex;
  align-items: center;
  margin-right: auto;
  margin: 0 auto;
  gap: 20px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  @media screen and (max-width: 767px) {
    margin-right: 0;
    margin-left: 0;
    &.menu-fixed {
      position: fixed;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      left: 0;
      padding: 40px 0 20px;
      top: 56px;
      background: rgba(28, 28, 28, 0.85);
      color: #fff;
      transition: all 0.15s linear;
      opacity: 0;
      overflow-y: auto;
      width: 100%;
      height: calc(100vh - 50px);
      backdrop-filter: blur(13px);
      z-index: 700;
      &.isIOS {
        padding-bottom: 40px;
        padding-top: 20px;
        gap: 10px;
        height: calc(100vh - 80px);
      }
      & > div {
        &:not(:last-child) {
          padding: 0 30px 40px;
        }
      }
    }
  }
`
export const ButtonHamburger = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 35px;
  height: 35px;
  flex-shrink: 0;
  padding: 8px;
  background-color: #fff;
  border-radius: 6px;
  span {
    background-color: #494949;
    width: 100%;
    height: 2px;
    border-radius: 20px;
    display: inline-block;
    transition: all 0.15s ease;
    &:nth-child(2),
    &:nth-child(3) {
      margin-top: 5px;
    }
  }
  &.active {
    span {
      position: relative;
      &:nth-child(1) {
        top: 7px;
        transform: rotate(45deg);
      }
      &:nth-child(2) {
        opacity: 0;
      }
      &:nth-child(3) {
        transform: rotate(-45deg);
        top: -7px;
      }
    }
  }
`
export const HeaderWrapper = styled.div`
  max-width: 1080px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const UserBlockMobile = styled.div`
  width: 100%;
  transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  ${FormMask} {
    padding: 60px 20px 30px;
    width: 100%;
    margin: 0;
    min-height: 310px;
    background-image: url(/images/bunny/Form/form_mask_mobile.png);
  }
  ${StyledButton} {
    width: 100%;
    padding: 15px 20px !important;
  }
`
export const UserCommunity = styled.div``
export const UserChain = styled.div`
  color: #000;
  font-size: 17px;
  width: 100%;
  border: 1px solid #cad2d9;
  padding: 12px;
  border-radius: 50px;
  margin-bottom: 10px;
`
export const UserWallet = styled.div`
  margin-bottom: 20px;
`
export const CommunityTitle = styled.div`
  color: #5f5f5f;
  font-size: 20px;
  letter-spacing: 0.2px;
  font-family: Outfit-Bold;
`
export const CommunityFlex = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  & > p {
    color: #888;
    font-size: 14px;
  }
`
export const CommunitySocials = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  & > a {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid rgba(161, 174, 229, 0.11);
    width: 48px;
    height: 48px;
    background-color: #f5f6fc;
  }
`
