import { FromOverlayer } from 'Layout/styled'
import { CreateSubHeader } from 'pages/AddToken/styled'
import {
  CopyAddress,
  CreateHistoryBody,
  CreateHistoryItem,
  HistoryItemLeft,
  HistoryItemRight,
  NoHistory,
} from './styled'
import toast from 'react-hot-toast'
import CopyToClipboard from 'react-copy-to-clipboard'
import { convertFixed } from 'utils/convertNumber'

const CreateHistoryToken = () => {
  const data = JSON.parse(localStorage.getItem('createdTokens') || '[]')
  const truncateNumber = (num) => {
    const strNum = num.toString()
    return strNum.length > 12 ? strNum.slice(0, 12) + '...' : strNum
  }

  return (
    <FromOverlayer className='long-form' style={{
      height: "100%",
    }}>
      <CreateSubHeader>
        <h2>Created Token</h2>
        <p>{data.length}</p>
      </CreateSubHeader>
      <CreateHistoryBody>
        {data.length > 0 ? (
          <>
            {data.map((item, index) => {
              return (
                <CreateHistoryItem key={index}>
                  <HistoryItemLeft>
                    <p>{item.tokenName}</p>
                    <CopyToClipboard
                      text={`https://bscscan.com/token/${item.addressCreated}`}
                      onCopy={() => {
                        toast.success('Copied')
                      }}
                    >
                      <CopyAddress>
                        <p>Copy Address</p>
                        <img width={14} height={14} src="/images/addToken/copy_address.svg" alt="icon" />
                      </CopyAddress>
                    </CopyToClipboard>
                  </HistoryItemLeft>
                  <HistoryItemRight>
                    <p>Total Supply</p>
                    <p>{truncateNumber(convertFixed(item.totalSupply))}</p>
                  </HistoryItemRight>
                </CreateHistoryItem>
              )
            })}
          </>
        ) : (
          <>
            <NoHistory>
              <figure>
                <img src="/images/addToken/gif/history_addToken.gif" alt="gif" />
              </figure>
              <p>No token added.</p>
            </NoHistory>
          </>
        )}
      </CreateHistoryBody>
    </FromOverlayer>
  )
}

export default CreateHistoryToken
