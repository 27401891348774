/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'

export const SliderCommon = styled.div`
  width: 100%;
  .ant-slider-track {
    background-color: #00b2c8;
  }
  .ant-slider:hover .ant-slider-track {
    background-color: #00b2c8;
  }
  .ant-slider-rail,
  .ant-slider:hover .ant-slider-rail,
  .ant-slider .ant-slider-dot {
    background-color: #9a9a9a;
  }
  .ant-slider .ant-slider-dot {
    border-color: #9a9a9a;
  }
  .ant-slider:hover .ant-slider-dot {
    border-color: transparent;
  }
  .ant-slider:hover .ant-slider-dot-active,
  .ant-slider-dot-active {
    background-color: #00b2c8 !important;
    border-color: #00b2c8 !important;
  }
  .ant-slider-horizontal .ant-slider-handle {
    inset-block-start: 0 !important;
  }
  .ant-slider .ant-slider-handle:focus::before {
    inset-inline-start: 0;
    inset-block-start: 0;
    width: 12px;
    height: 12px;
  }
  .ant-slider .ant-slider-handle::after {
    box-shadow: 0 0 0 4px #00b2c8 !important;
  }
  .ant-slider .ant-slider-handle {
    &::after {
      background-color: #00b2c8 !important;
      inset-inline-start: 0px;
      inset-block-start: 0px;
    }
    &:hover {
      &::after {
        box-shadow: 0 0 0 4px #00b2c8 !important;
      }
    }
  }
`
export const BackButton = styled.figure`
  width: 24px;
  height: 24px;
  transition: all 0.15s linear;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  img {
    width: 100%;
  }
`;