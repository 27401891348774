import React from "react";
import styled from "styled-components";
import getExternalLinkProps from "../../utils/getExternalLinkProps";
import Text from "../Text/Text";
import { LinkProps } from "./types";

const StyledLink = styled(Text)<LinkProps>`
  display: flex;
  align-items: center;
  width: fit-content;
  color: #0D0C43;
  font-size: 16px;
  &:hover {
    text-decoration: underline;
  }
`;

const Link = ({ external, ...props }) => {
  const internalProps = external ? getExternalLinkProps() : {};
  return <StyledLink as="a" bold {...internalProps} {...props} />;
};

export default Link;
