import React from 'react'
import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core'
import { Provider } from 'react-redux'
import { ModalProvider } from 'widgets/Modal'
import { ThemeContextProvider } from 'ThemeContext'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config'
import { bsc, bscTestnet } from 'viem/chains'
import { createWeb3Modal } from '@web3modal/wagmi/react'
import { WagmiProvider } from 'wagmi'
import store from './state'
import getLibrary from './utils/getLibrary'
import { NetworkContextName } from './constants'

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName)

const Providers = ({ children }: any) => {
  const queryClient = new QueryClient()

  const projectId = '3a1eba421630b4028ff59600db61d251'

  const metadata = {
    name: 'Bunny',
    description: 'Bunny',
    url: 'https://bunnychz.com/',
    icons: ['https://bunnyswap.org/logo256.png'],
  }

  const chains = [bsc] as const
  const config = defaultWagmiConfig({
    chains,
    projectId,
    metadata,
  })

  createWeb3Modal({
    wagmiConfig: config,
    projectId,
    defaultChain: bsc
  })

  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <Web3ReactProvider getLibrary={getLibrary}>
          <Web3ProviderNetwork getLibrary={getLibrary}>
            <ThemeContextProvider>
              <Provider store={store}>
                <ModalProvider>
                  {children}
                </ModalProvider>
              </Provider>
            </ThemeContextProvider>
          </Web3ProviderNetwork>
        </Web3ReactProvider>
      </QueryClientProvider>
    </WagmiProvider>
  )
}

export default Providers