import React, { useContext } from 'react'
import { useAccount } from 'wagmi'
import { ConnectorId } from '@bscstation/uikit'
import { useWeb3React } from '@web3-react/core'
import { allLanguages } from 'constants/localisation/languageCodes'
import { LanguageContext } from 'hooks/LanguageContext'
import useTheme from 'hooks/useTheme'
import useGetLocalProfile from 'hooks/useGetLocalProfile'
import { injected, bsc, walletconnect } from 'connectors'
import links from './config'
import {Menu as MenuKit} from '../../widgets/Menu'

const Menu = (props) => {
  const { activate, deactivate } = useWeb3React()
  const { address } = useAccount()
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext)
  const { isDark, toggleTheme } = useTheme()
  const profile = useGetLocalProfile()

  return (
    <MenuKit
      links={links}
      account={address as string}
      login={(connectorId: ConnectorId) => {
        if (connectorId === 'walletconnect') {
          return activate(walletconnect)
        }

        if (connectorId === 'bsc') {
          return activate(bsc)
        }

        return activate(injected)
      }}
      logout={deactivate}
      isDark={isDark}
      toggleTheme={toggleTheme}
      currentLang={selectedLanguage?.code || ''}
      langs={allLanguages}
      setLang={setSelectedLanguage}
      cakePriceUsd={0.01111}
      profile={profile}
      {...props}
    />
  )
}

export default Menu
