import styled from "styled-components";
import { BaseButtonProps } from "./types";

const StyledButton = styled.button<BaseButtonProps>`
  align-items: center;
  padding: 12px 20px;
  border: 0;
  border-radius: 999px;
  background: ${({ isNormal }) => isNormal ? "#FFD100" : "transparent"};
  cursor: pointer;
  display: flex;
  font-size: 15px;
  justify-content: center;
  outline: 0;
  transition: background-color 0.2s;
  border: 1px solid ${({ isNormal }) => (isNormal ? "transparent" : "#FFD100")};
  width: ${({ width }) => width};
  color: #000;
  transition: all .15s linear;
`;

export default StyledButton;
