import styled from "styled-components";

export const CreateHistoryBody = styled.div`
  position: relative;
  max-height: 246px;
  overflow-y: auto;
  margin-top: 30px;
`
export const NoHistory = styled.div`
  padding: 20px 0;
  & > figure {
    width: 62px;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }
  & > p {
    color: #717696;
    text-align: center;
    font-size: 14px;
    letter-spacing: 0.14px;
  }
`
export const CreateHistoryItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  background: #F5F7FF;
  padding: 6px 12px;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`
export const HistoryItemLeft = styled.div`
  & > p {
    &:nth-child(1) {
      color: #0D0C43;
      font-family: Outfit-Medium;
      font-size: 14px;
    }
  }
`
export const HistoryItemRight = styled.div`
  & > p {
    color: #5771FF;
    &:nth-child(1) {
      font-size: 12px;
      text-align: right;
    }
    &:nth-child(2) {
      font-size: 16px;
      text-align: right;
      font-family: Outfit-Medium;
    }
  }
`
export const CopyAddress = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 5px;
  & > p {
    color: #004CCD;
    font-family: Outfit-Medium;
    font-size: 13px;
    text-decoration-line: underline;
  }
`