import React, { useContext, useEffect, useState } from 'react'
import { isIOS } from 'react-device-detect'
import { FormMask } from 'Layout/styled'
import styled from 'styled-components'
import { useAccount } from 'wagmi'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { ContextProviderWrapper } from 'components/Context'
import { useMatchBreakpoints } from '@bscstation/uikit'
import Overlay from '../../components/Overlay/Overlay'
import {
  ButtonHamburger,
  CommunityFlex,
  CommunitySocials,
  CommunityTitle,
  HeaderLogo,
  HeaderNav,
  HeaderWrapper,
  NavItemWrap,
  UserBlockMobile,
  UserChain,
  UserCommunity,
  UserNav,
  UserWallet,
} from './styled'
import Flex from '../../components/Box/Flex'
import UserBlock from './UserBlock'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`

const StyledNav = styled.nav<{ showMenu: boolean }>`
  transition: top 0.2s;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  width: 100%;
  background: #286fed;
  z-index: 20;
`

const BodyWrapper = styled.div`
  position: relative;
  display: flex;
  height: 100vh;
`

const Inner = styled.div<{ isPushed: boolean; showMenu: boolean }>`
  transition: margin-top 0.2s;
  max-width: 100%;
  width: 100%;
`

const MobileOnlyOverlay = styled(Overlay)`
  position: fixed;
  height: 100%;

  ${({ theme }) => theme.mediaQueries.nav} {
    display: none;
  }
`

const Menu = ({ account, login, logout, children }: any) => {
  const { isXl } = useMatchBreakpoints()
  const isMobile = isXl === false
  const [isPushed, setIsPushed] = useState(!isMobile)
  const [showMenu, setShowMenu] = useState(true)
  const { isMobile: isMobileContext } = useContext(ContextProviderWrapper)!
  const [openHamburger, setOpenHamburger] = useState(false)
  const { pathname } = useLocation()
  const { chainId } = useAccount()

  useEffect(() => {
    if (openHamburger) {
      document.body.classList.add('hidden')
    } else {
      document.body.classList.remove('hidden')
    }
  }, [openHamburger])

  const navData = [
    {
      id: 1,
      text: 'About',
      href: 'https://bunnychz.com/#about',
      externalLink: true,
      delay: '0.2s',
    },
    {
      id: 2,
      text: 'Mission',
      href: 'https://bunnychz.com/#mission',
      externalLink: true,
      delay: '0.4s',
    },
    {
      id: 3,
      text: 'Tokenomics',
      href: 'https://bunnychz.com/#tokenomics',
      externalLink: true,
      delay: '0.6s',
    },
    {
      id: 4,
      text: 'Roadmap',
      href: 'https://bunnychz.com/#roadmap',
      externalLink: true,
      delay: '0.8s',
    },
    // {
    //   id: 5,
    //   text: 'Create Token',
    //   href: '/v2/createToken',
    //   externalLink: false,
    //   delay: '1s',
    // },
    {
      id: 6,
      text: 'Trade',
      href: '/v2/swap',
      externalLink: false,
      delay: '1.2s',
    },
    // {
    //   id: 7,
    //   text: 'Whitepaper',
    //   href: 'https://bunnychz.gitbook.io/whitepaper',
    //   externalLink: true,
    //   delay: '1.4s',
    // },
  ]

  return (
    <Wrapper>
      <StyledNav showMenu={showMenu}>
        <HeaderWrapper>
          <HeaderLogo>
            <Link to="/">
              <figure>
                <img src="../images/bunny/Logo/header_logo.png" alt="logo" />
              </figure>
            </Link>
          </HeaderLogo>
          <HeaderNav
            className={isMobileContext && !isIOS ? 'menu-fixed' : isMobileContext && isIOS ? 'menu-fixed isIOS' : ''}
            style={
              isMobileContext && openHamburger
                ? { opacity: '1' }
                : !isMobileContext
                  ? { pointerEvents: 'auto' }
                  : { pointerEvents: 'none' }
            }
          >
            <UserNav>
              {navData.map((item) => {
                return (
                  <NavItemWrap
                    key={item.id}
                    className={isMobileContext && openHamburger ? 'open' : ''}
                    Delay={item.delay}
                  >
                    {item.externalLink ? (
                      <>
                        <a href={item.href} target="_blank" rel="noreferrer">
                          {item.text}
                        </a>
                      </>
                    ) : (
                      <>
                        <NavLink
                          className={
                            item.id === 6 && (pathname === '/swap' || pathname === '/liquidity') ? 'active-both' : ''
                          }
                          to={item.href}
                        >
                          {item.text}
                        </NavLink>
                      </>
                    )}
                  </NavItemWrap>
                )
              })}
            </UserNav>
            {isMobileContext && (
              <UserBlockMobile
                style={
                  isMobileContext && openHamburger
                    ? { transform: 'translateY(-40px)' }
                    : { transform: 'translateY(310px)' }
                }
              >
                <FormMask>
                  <UserWallet>
                    <UserChain>{chainId === 56 ? 'Binance Smart Chain' : '--'}</UserChain>
                    <UserBlock account={account} />
                  </UserWallet>
                  <UserCommunity>
                    <CommunityTitle>Join our community</CommunityTitle>
                    <CommunityFlex>
                      <p>Let’s join Bunny in supporting Ukraine and enjoy victory together!</p>
                      <CommunitySocials>
                        <Link to="/" target="_blank" rel="noopener">
                          <img src="/images/bunny/Social/tele.svg" alt="icon" />
                        </Link>
                        <Link to="/" target="_blank" rel="noopener">
                          <img src="/images/bunny/Social/twitter.svg" alt="icon" />
                        </Link>
                      </CommunitySocials>
                    </CommunityFlex>
                  </UserCommunity>
                </FormMask>
              </UserBlockMobile>
            )}
          </HeaderNav>
          {!isMobileContext && (
            <Flex>
              <UserBlock account={account} />
            </Flex>
          )}
          {isMobileContext && (
            <ButtonHamburger
              className={openHamburger ? 'active' : ''}
              onClick={() => {
                setOpenHamburger(!openHamburger)
              }}
            >
              <span />
              <span />
              <span />
            </ButtonHamburger>
          )}
        </HeaderWrapper>
      </StyledNav>
      <BodyWrapper>
        <Inner isPushed={isPushed} showMenu={showMenu}>
          {children}
        </Inner>
        <MobileOnlyOverlay show={isPushed} onClick={() => setIsPushed(false)} role="presentation" />
      </BodyWrapper>
    </Wrapper>
  )
}

export default Menu
