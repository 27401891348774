import React, { ReactNode } from 'react'
import { Flex } from 'components/Box'
import styled from 'styled-components'
import { IconButton } from 'components/Button'
import { Heading } from 'components/Heading'
import { Text } from 'components/Text'
import { useModal } from 'widgets/Modal'
import SettingsModal from './SettingsModal'
import RecentTransactionsModal from './RecentTransactionsModal'

interface PageHeaderProps {
  title: ReactNode
  description?: ReactNode
  children?: ReactNode
  isSettings?: boolean
}
export const StyledPageHeader = styled.div`
  padding: 20px 20px 10px;
`
export const WrapperBackButton = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 20px 20px 0;
  ${StyledPageHeader} {
    padding: 0;
  }
`
const Details = styled.div`
  flex: 1;
`

const PageHeader = ({ title, description, children, isSettings }: PageHeaderProps) => {
  const [onPresentSettings] = useModal(<SettingsModal />)
  const [onPresentRecentTransactions] = useModal(<RecentTransactionsModal />)

  return (
    <StyledPageHeader>
      <Flex alignItems="center">
        <Details>
          <Heading>{title}</Heading>
          {description && (
            <Text style={{whiteSpace: "pre-line"}} color="textSubtle" fontSize="14px">
              {description}
            </Text>
          )}
        </Details>
        {isSettings && <>
          <IconButton style={{ border: "none" }} variant="text" onClick={onPresentRecentTransactions} title="Recent transactions">
            <img src="/images/bunny/Icons/history_icon.png" alt="icon" />
          </IconButton>
          <IconButton style={{ border: "none" }} variant="text" onClick={onPresentSettings} title="Settings">
            <img src="/images/bunny/Icons/settings_icon.png" alt="icon" />
          </IconButton>
        </>}
      </Flex>
      {children && <Text mt="16px">{children}</Text>}
    </StyledPageHeader>
  )
}

export default PageHeader
