import { Trade } from '@tooldex/sdk'
import { Text } from 'components/Text'
import React, { Fragment, memo, useContext } from 'react'
import { ChevronRight } from 'react-feather'
import { Flex } from '@bscstation/uikit'
import { ThemeContext } from 'styled-components'
import CurrencyLogo from '../CurrencyLogo'

export default memo(function SwapRoute({ trade }: { trade: Trade }) {
  const theme = useContext(ThemeContext)
  return (
    <Flex
      px="1rem"
      py="0.5rem"
      mt="0.5rem"
      style={{ border: `1px solid rgb(202, 210, 217)`, borderRadius: '1rem' }}
      flexWrap="wrap"
      justifyContent="space-evenly"
      alignItems="center"
    >
      {trade.route.path.map((token, i, path) => {
        const isLastItem: boolean = i === path.length - 1
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={i}>
            <Flex my="0.5rem" alignItems="center" style={{ flexShrink: 0 }}>
              <CurrencyLogo currency={token} size="1.5rem" />
              <Text fontSize="14px" color="text" style={{ marginLeft: '5px', color: '#004CCD' }}>
                {token.symbol}
              </Text>
            </Flex>
            {isLastItem ? null : <ChevronRight color="textSubtle" />}
          </Fragment>
        )
      })}
    </Flex>
  )
})
