/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'

export const FormMask = styled.div`
  position: relative;
  background: url(${'../images/bunny/Form/form_mask_modal.png'}) no-repeat center / 100% 100%;
  padding: 35px 30px 15px;
  margin: 0 -20px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  z-index: 2;
  &.Notconnect {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media screen and (max-width: 767px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`
export const FromOverlayer = styled.div`
  position: relative;
  background-color: #EFF2FF;
  box-shadow: 0px -7px 0px 0px rgba(84, 132, 255, 0.25) inset, 0px 4px 20px 0px rgba(99, 98, 141, 0.12);
  border-radius: 20px;
  min-height: 165px;
  overflow: hidden;
  padding: 10px 20px;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(100% - 6px);
    background: url("/images/bunny/Form/form_overlayer.png") no-repeat center / 100% 100%;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  @media screen and (min-width: 768px) {
    &.long-form {
      &::before {
        background-image: url("/images/bunny/Form/form_overlayer_2.png");
      }
    }
  }
`
