import React, { useContext, useEffect, useState } from 'react'
import { AddTokenContainer, AddTokenMain, AddTokenSub, AddTokenWrapper } from './styled'
import AddTokenForm from './Form/Main'
import CreateTokenProcess from './Form/Progress'
import CreateHistoryToken from './Form/History'
import { useAccount } from 'wagmi'
import { ContextProviderWrapper } from 'components/Context'
import { WORk_CHAIN } from 'constants/index'

const AddToken = () => {
  const [stateSwitch, setStateSwitch] = useState(1)
  const { address, chainId, isDisconnected } = useAccount()
  const { isMobile } = useContext(ContextProviderWrapper)!
  const [prevAddress, setPrevAddress] = useState<any>("0x00");

  useEffect(() => {
    if (address && chainId === WORk_CHAIN) {
      setStateSwitch(2)
      setPrevAddress(address)
    } else {
      setStateSwitch(1)
    }
  }, [address, chainId])

  // Clear history when address changes
  // Initialize local storage with the address if it's the first time
  useEffect(() => {
    if (prevAddress !== address) {
      localStorage.setItem('walletAddress', prevAddress);
    }
  }, [prevAddress, address]);

  // Clear history and update wallet address in local storage when address changes
  useEffect(() => {
    const localAddress = localStorage.getItem('walletAddress');
    if (localAddress && address && address !== localAddress && localAddress !== "0x00" || isDisconnected) {
      localStorage.setItem('createdTokens', '[]');
    }
  }, [address, isDisconnected]);

  return (
    <AddTokenContainer>
      <AddTokenWrapper>
        <AddTokenMain>
          {isMobile && <CreateTokenProcess stateSwitch={stateSwitch} />}
          <AddTokenForm setStateSwitch={setStateSwitch} stateSwitch={stateSwitch} />
        </AddTokenMain>
        <AddTokenSub>
          {!isMobile && <CreateTokenProcess stateSwitch={stateSwitch} />}
          <CreateHistoryToken />
        </AddTokenSub>
      </AddTokenWrapper>
    </AddTokenContainer>
  )
}

export default AddToken
