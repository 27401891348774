import React from 'react'
import { FormMask } from 'Layout/styled'
import { Modal } from 'widgets/Modal'
import SlippageToleranceSettings from './SlippageToleranceSetting'
import TransactionDeadlineSetting from './TransactionDeadlineSetting'

type SettingsModalProps = {
  onDismiss?: () => void
}

// TODO: Fix UI Kit typings
const defaultOnDismiss = () => null

const SettingsModal = ({ onDismiss = defaultOnDismiss }: SettingsModalProps) => {
  return (
    <Modal title="Settings" onDismiss={onDismiss}>
      <FormMask style={{paddingTop: '25px'}}>
        <SlippageToleranceSettings />
        <TransactionDeadlineSetting />
      </FormMask>
    </Modal>
  )
}

export default SettingsModal
