import styled from "styled-components";

export const BalanceTokenWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 15px;
`;
export const BalanceBlock = styled.div`
  width: 50%;
  background-color: #fff;
  padding: 6px 12px;
  border-radius: 10px;
  & > div {
    display: flex;
    align-items: center;
    gap: 5px;
    figure {
      width: 20px;
      height: 20px;
      img {
        width: 100%;
      }
    }
    & > p {
      font-size: 14px;
      color: #0D0C43;
    }
  }
  & > P {
    color: #00B2C8;
    text-align: right;
  }
`;
