import styled from "styled-components";

export const InputContainer = styled.div`
  width: 100%;
  .ant-input-affix-wrapper,
  input {
    padding: 7px 14px;
    border-radius: 10px;
    border: 1px solid #CAD2D9;
    background: #FFF;
    color: #0D0C43;
    font-size: 14px;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    /* Firefox */
    &[type=number] {
      -moz-appearance: textfield;
    }
  }
  .ant-input-affix-wrapper {
    input {
      font-size: 14px;
      border-radius: 0;
    }
  }
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused,
  .ant-input-focused,
  .ant-input:focus,
  .ant-input-affix-wrapper:hover,
  .ant-input:hover {
    border-color: rgb(255, 209, 0);
    box-shadow: none;
  }
  .ant-input-affix-wrapper-disabled {
    background-color: #f5f5f5 !important;
  }
  .ant-input-prefix {
    margin-right: 5px;
  }
  .ant-input-group-addon {
    background: #EBF0F3;
    font-size: 14px;
    color: #0D0C43;
    &:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    &:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
  .addon-input {
    .ant-input {
      padding-top: 17px;
      padding-bottom: 17px;
    }
  }
`