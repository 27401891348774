/* eslint-disable import/prefer-default-export */
import styled from "styled-components";

export const CardNavPre = styled.div`
  .nav-link-button {
    padding: 10px 20px;
    border: none;
    color: #E3E3E3;
    border-radius: 90px;
    &.active{
      background: #FFD100 !important;
      color: #0D0C43 !important;
    };
  }
  &:hover a{
    color: #fff !important;
  }
`