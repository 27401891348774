import React from 'react'
import { CopyAddressComplete, FormCompleteContainer, FormCompleteInfo, FormCompleteWrapper } from './styled'
import toast from 'react-hot-toast';
import { FormValueButtons } from '../FormValue/styled';
import { Button } from 'components/Button';
import CopyToClipboard from "react-copy-to-clipboard";
import { useHistory } from 'react-router-dom';

const FormComplete = ({ tokenInfo, setStateSwitch, setTokenInfo }: any) => {
  const navigate = useHistory()
  return (
    <FormCompleteContainer>
      <FormCompleteWrapper>
        <figure>
          <img src="/images/addToken/gif/complete_create.gif" alt="gif" />
        </figure>
        <FormCompleteInfo>
          <p>{tokenInfo?.tokenName + " - " + tokenInfo?.tokenSymbol} was created</p>
          <div>
            <p>{tokenInfo?.addressCreated?.slice(0, 5)}...{tokenInfo?.addressCreated?.slice(tokenInfo?.addressCreated.length - 4)}</p>
            <CopyToClipboard
              text={tokenInfo?.addressCreated}
              onCopy={() => {
                toast.success("Copied");
              }}
            >
              <CopyAddressComplete>
                <img width={14} height={14} src="/images/addToken/copy_alt.svg" alt="icon" />
                <p>Copy</p>
              </CopyAddressComplete>
            </CopyToClipboard>
          </div>
        </FormCompleteInfo>
      </FormCompleteWrapper>
      <FormValueButtons>
        <Button
          style={{ width: '40%' }}
          onClick={() => navigate.push(`/v2/add/BNB/${tokenInfo?.addressCreated}`)}
        >
          Add Liquidity
        </Button>
        <Button
          isNormal
          style={{ width: '60%' }}
          onClick={() => {
            setStateSwitch(2);
            setTokenInfo({
              tokenName: "",
              tokenSymbol: "",
              decimals: 18,
              totalSupply: 0,
              addressCreated: ""
            })
          }}
        >
          Create new token
        </Button>
      </FormValueButtons>
    </FormCompleteContainer>
  )
}

export default FormComplete