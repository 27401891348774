import React from 'react'
import { Button } from 'components/Button'
import { useWeb3Modal } from '@web3modal/wagmi/react'
import { useAccount } from 'wagmi'

const UnlockButton = props => {
  const { address } = useAccount()
  const { open } = useWeb3Modal()
  
  return (
    <Button isNormal onClick={() => open()} {...props} style={{ width: "100%", borderRadius: "10px", height: "46px" }}>
      {address || "Connect Wallet"}
    </Button>
  )
}

export default UnlockButton
