import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <img style={{cursor: "pointer"}} src="/images/bunny/Icons/icn_close_gray.svg" alt="icon" {...props} />
  );
};

export default Icon;
