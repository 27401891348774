import random from 'lodash/random'

// Array of available nodes to connect to
export const nodes = ['https://bsc-dataseed4.ninicoin.io/', 'https://bsc-dataseed3.ninicoin.io/', 'https://bsc-dataseed2.ninicoin.io/']

const getNodeUrl = () => {
  const randomIndex = random(0, nodes.length - 1)
  return nodes[randomIndex]
}

export default getNodeUrl
