import React from 'react'
import { Trade, TradeType } from '@tooldex/sdk'
import { Text } from 'components/Text'
import Card from 'components/Card'
import { FormMask } from 'Layout/styled'
import CardBody from 'components/Card/CardBody'
import Value from 'components/Value'
import { Field } from '../../state/swap/actions'
import { useUserSlippageTolerance } from '../../state/user/hooks'
import { computeSlippageAdjustedAmounts, computeTradePriceBreakdown } from '../../utils/prices'
import { AutoColumn } from '../Column'
import QuestionHelper from '../QuestionHelper'
import { RowBetween, RowFixed } from '../Row'
import FormattedPriceImpact from './FormattedPriceImpact'
import SwapRoute from './SwapRoute'
import { TextTitle } from './styleds'

function TradeSummary({ trade, allowedSlippage }: { trade: any; allowedSlippage: number }) {
  const { priceImpactWithoutFee, realizedLPFee } = computeTradePriceBreakdown(trade)
  const isExactIn = trade?.tradeType === TradeType.EXACT_INPUT || "--"
  const slippageAdjustedAmounts = computeSlippageAdjustedAmounts(trade, allowedSlippage)
  const showRoute = Boolean(trade && trade.route.path.length > 2)
  return (
    <Card style={{ background: "transparent", padding: 0 }}>
      <CardBody style={{ marginBottom: showRoute ? '5px' : '0' }}>
        <RowBetween>
          <RowFixed>
            <Text fontSize="14px">{isExactIn ? 'Minimum received' : 'Maximum sold'}</Text>
          </RowFixed>
          <RowFixed>

            <Text fontSize="14px" style={{ color: '#004CCD' }}>
              {isExactIn
                ? <Value
                  size={14}
                  color='#004CCD'
                  unitafter={trade?.outputAmount.currency.symbol}
                  value={Number(slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4))}
                />
                : <Value
                  size={14}
                  color='#004CCD'
                  unitafter={trade?.inputAmount.currency.symbol}
                  value={Number(slippageAdjustedAmounts[Field.INPUT]?.toSignificant(4))}
                />}
            </Text>
          </RowFixed>
        </RowBetween>
        <RowBetween>
          <RowFixed>
            <Text fontSize="14px">Price Impact</Text>
          </RowFixed>
          <FormattedPriceImpact priceImpact={priceImpactWithoutFee} />
        </RowBetween>

        <RowBetween>
          <RowFixed>
            <Text fontSize="14px">Liquidity Provider Fee</Text>
          </RowFixed>
          <Text fontSize="14px" style={{ color: '#004CCD' }}>
            <Value
              size={14}
              color='#004CCD'
              unitafter={trade?.inputAmount.currency.symbol}
              value={Number(realizedLPFee?.toSignificant(4))}
            />
          </Text>
        </RowBetween>
      </CardBody>
      {showRoute && (
        <>
          <AutoColumn>
            <RowFixed>
              <Text fontSize="14px">Route</Text>
              <QuestionHelper text="Routing through these tokens resulted in the best price for your trade." />
            </RowFixed>
            <SwapRoute trade={trade} />
          </AutoColumn>
        </>
      )}
    </Card>
  )
}

export interface AdvancedSwapDetailsProps {
  trade?: Trade
}

export function AdvancedSwapDetails({ trade }: AdvancedSwapDetailsProps) {
  const [allowedSlippage] = useUserSlippageTolerance()

  return <AutoColumn gap="md"><TradeSummary trade={trade} allowedSlippage={allowedSlippage} /></AutoColumn>
}
