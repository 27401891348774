import React, { useContext } from 'react'
import { ContextProviderWrapper } from 'components/Context'
import { useWeb3Modal } from '@web3modal/wagmi/react'
import Button from '../../components/Button/Button'

const UserBlock = ({ account }) => {
  const { open } = useWeb3Modal()
  const accountEllipsis = account ? `${account.substring(0, 4)}...${account.substring(account.length - 4)}` : null
  const { isMobile } = useContext(ContextProviderWrapper)!
  return (
    <div>
      {account ? (
        <Button
          scale="sm"
          variant="tertiary"
          width={isMobile ? '120px' : '150px'}
          style={{ paddingTop: isMobile ? '8px' : '', paddingBottom: isMobile ? '8px' : '' }}
          isNormal
          color="#000"
          onClick={() => {
            open()
          }}
        >
          {accountEllipsis}
        </Button>
      ) : (
        <Button
          scale="sm"
          width={isMobile ? '120px' : '150px'}
          style={{ paddingTop: isMobile ? '8px' : '', paddingBottom: isMobile ? '8px' : '' }}
          isNormal
          color="#000"
          onClick={() => {
            open()
          }}
        >
          Connect Wallet
        </Button>
      )}
    </div>
  )
}

export default UserBlock
