import { MenuEntry } from '@bscstation/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    initialOpenState: false,
    items: [
      {
        label: 'Exchange',
        href: '/swap',
      },
      {
        label: 'Liquidity',
        href: '/pool',
      },
    ],
  },
  {
    label: 'NFT',
    icon: 'NftIcon',
    initialOpenState: false,
    items: [
      {
        label: 'Megamarket',
        href: '/nft-megamarket',
      },
      {
        label: 'Genesis Market',
        href: '/genesis-market',
      },
      {
        label: 'INO',
        href: '/ino',
      },
      {
        label: 'Stake NFT',
        href: '/stakeNFT',
      },
      {
        label: 'INO New',
        href: '/ino-new',
      },
      {
        label: 'My NFT Artworks',
        href: '/my-artworks',
      },
    ],
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: 'https://stake.bscstation.finance/#/farms',
  },
  {
    label: 'Start Pools',
    icon: 'StakingIcon',
    href: 'https://stake.bscstation.finance/#/',
  },
  {
    label: 'IDO Pools',
    icon: 'IDOPoolIcon',
    href: 'https://stake.bscstation.finance/#/ido-pools',
  },
  // {
  //   label: 'Launchpad',
  //   icon: 'TicketIcon',
  //   href: '/launchpad',
  // },
  // {
  //   label: 'Launchpad',
  //   icon: 'TicketIcon',
  //   initialOpenState: false,
  //   items: [
  //     {
  //       label: 'Gamefi',
  //       href: 'http://localhost:3000/#/launchpadv3?gamefi',
  //     },
  //     {
  //       label: 'Metaverse',
  //       href: 'http://localhost:3000/#/launchpadv3?meta',
  //     },
  //     {
  //       label: 'Defi',
  //       href: 'http://localhost:3000/#/launchpadv3?defi',
  //     },
  //   ],
  // },
  {
    label: 'Launchpad',
    icon: 'TicketIcon',
    href: '/launchpad',
  },
  {
    label: 'Prediction',
    icon: 'PredictionIcon',
    href: '/prediction',
  },
  {
    label: 'More',
    icon: 'MoreIcon',
    items: [
      {
        label: 'Github',
        href: 'https://github.com/BSCStationSwap',
      },
      {
        label: 'Docs',
        href: 'https://docs.bscstation.finance/',
      },
      {
        label: 'Blog',
        href: 'https://bscstation.medium.com',
      },
      {
        label: 'Certik Audit',
        href: 'https://www.certik.org/projects/bscstation',
      },
      {
        label: 'PeckShield Audit',
        href: 'https://github.com/peckshield/publications/blob/master/audit_reports/PeckShield-Audit-Report-BSCStationStartPools-v1.0.pdf',
      },
      {
        label: 'Quillhash Audit',
        href: 'https://github.com/Quillhash/Audit_Reports/blob/master/BSCS%20Smart%20Contract%20Audit%20Report%20-%20QuillAudits.pdf',
      },
    ],
  },
]

export default config
