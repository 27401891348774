import React from 'react'
import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'
import { ButtonMenu, ButtonMenuItem } from 'components/ButtonMenu'
import TranslatedText from '../TranslatedText'
import { CardNavPre } from './styled'

const StyledNav = styled.div`
  margin-bottom: 30px;
  margin-top: 50px;
`

const Nav = ({ activeIndex = 0 }: { activeIndex?: number }) => {
  const { pathname } = useLocation()
  return (
    <StyledNav>
      <ButtonMenu activeIndex={activeIndex} variant="subtle">
        <CardNavPre>
          <ButtonMenuItem id="swap-nav-link" className={pathname === "/v2/swap" ? "active nav-link-button" : "nav-link-button"} to="/v2/swap" as={Link}>
            <TranslatedText translationId={8}>BunnySwap</TranslatedText>
          </ButtonMenuItem>
        </CardNavPre>
        <CardNavPre>
          <ButtonMenuItem id="pool-nav-link" className={pathname === "/v2/pool" || pathname.includes("add") ? "active nav-link-button" : "nav-link-button"} to="/v2/pool" as={Link}>
            <TranslatedText translationId={74}>Add Liquidity</TranslatedText>
          </ButtonMenuItem>
        </CardNavPre>
      </ButtonMenu>
    </StyledNav>
  )
}

export default Nav
