import styled from "styled-components";
import Text from "../Text/Text";
import { tags, HeadingProps } from "./types";

const Heading = styled(Text).attrs({ bold: true })<HeadingProps>`
  color: #0D0C43;
  letter-spacing: 0.16px;
  font-size: 16px;
`;

Heading.defaultProps = {
  as: tags.H2,
};

export default Heading;
