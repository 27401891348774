import styled from "styled-components";

export const FormValueContainer = styled.div`
  width: 100%;
`
export const FormValueBlock = styled.div`
  .ant-select {
    height: auto;
  }
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  & > p {
    color: #0D0C43;
    font-size: 14px;
    letter-spacing: 0.14px;
    margin-bottom: 5px;
  }
`
export const FormValueFlex = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  ${FormValueBlock} {
    width: 50%;
    margin-bottom: 0;
  }
`
export const FormValueButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`