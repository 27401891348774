import InputCommon from 'components/Common/Input'
import { FormValueBlock, FormValueButtons, FormValueContainer, FormValueFlex } from './styled'
import SelectCommon from 'components/Common/Select'
import { Button } from 'components/Button'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'

const FormValue = ({ setStateSwitch, setTokenInfo, tokenInfo }: any) => {
  const [stateContinue, setStateContinue] = useState(false)

  useEffect(() => {
    if (tokenInfo?.tokenName.length < 1 || tokenInfo?.tokenSymbol.length < 1 || tokenInfo?.totalSupply < 1) {
      setStateContinue(false)
    } else {
      setStateContinue(true)
    }
  }, [tokenInfo, stateContinue])

  const handleCheckError = () => {
    if (tokenInfo?.tokenName.length < 1) {
      toast.error('Token name is invalid', { id: 'name' })
    } else if (tokenInfo?.tokenSymbol.length < 1) {
      toast.error('Token symbol is invalid', { id: 'symbol' })
    } else if (tokenInfo?.totalSupply < 1) {
      toast.error('Total supply is invalid', { id: 'supply' })
    }
  }

  return (
    <FormValueContainer>
      <FormValueBlock>
        <p>Token Name</p>
        <InputCommon
          value={tokenInfo?.tokenName}
          placeHolder="ex: Bunny"
          onChange={(e: any) => {
            setTokenInfo({ ...tokenInfo, tokenName: e.target.value })
          }}
        />
      </FormValueBlock>
      {/* <FormValueFlex> */}
        <FormValueBlock>
          <p>Token Symbol</p>
          <InputCommon
            value={tokenInfo?.tokenSymbol}
            placeHolder="ex: BUN"
            onChange={(e: any) => {
              setTokenInfo({ ...tokenInfo, tokenSymbol: e.target.value })
            }}
          />
        </FormValueBlock>
        <FormValueBlock>
          <p>Decimals</p>
          <SelectCommon
            data={[
              { title: '18', img: '' },
              { title: '12', img: '' },
              { title: '9', img: '' },
              { title: '6', img: '' },
            ]}
            value={tokenInfo?.decimals}
            onChange={(e: any) => {
              setTokenInfo({ ...tokenInfo, decimals: Number(e) })
            }}
          />
        </FormValueBlock>
      {/* </FormValueFlex> */}
      <FormValueBlock>
        <p>Total Supply</p>
        <InputCommon
          value={tokenInfo?.totalSupply}
          type="number"
          placeHolder="0"
          onChange={(e: any) => {
            setTokenInfo({ ...tokenInfo, totalSupply: Number(e.target.value) })
          }}
        />
      </FormValueBlock>
      <FormValueButtons style={{
        marginTop: '35px'
      }}>
        <Button
          isNormal
          style={{ width: '100%' }}
          onClick={() => (stateContinue ? setStateSwitch(3) : handleCheckError())}
        >
          Continue
        </Button>
      </FormValueButtons>
    </FormValueContainer>
  )
}

export default FormValue
