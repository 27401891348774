import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: Outfit-SemiBold;
    src: url(/fonts/Outfit-SemiBold.ttf);
  }

  @font-face {
    font-family: Outfit-Bold;
    src: url(/fonts/Outfit-Bold.ttf);
  }

  @font-face {
    font-family: Outfit-Light;
    src: url(/fonts/Outfit-Light.ttf);
  }

  @font-face {
    font-family: Outfit-Medium;
    src: url(/fonts/Outfit-Medium.ttf);
  }

  @font-face {
    font-family: Outfit-Regular;
    src: url(/fonts/Outfit-Regular.ttf);
  }

  @font-face {
    font-family: Outfit-Thin;
    src: url(/public/fonts/Outfit-Thin.ttf);
  }

  @font-face {
    font-family: Panton-Black;
    src: url(/fonts/Panton-Black.ttf);
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .hiden-scrollbar::-webkit-scrollbar {
    display: none !important;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .hiden-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  body {
    background-color: ${({ theme }) => theme.colors.background};
    img {
      height: auto;
      max-width: 100%;
    }
  }
`

export default GlobalStyle
