import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { CheckmarkCircleIcon, ErrorIcon } from '@bscstation/uikit'
import { getBscScanLink } from 'utils'
import { FormMask } from 'Layout/styled'
import { isTransactionRecent, useAllTransactions } from 'state/transactions/hooks'
import { TransactionDetails } from 'state/transactions/reducer'
import Loader from 'components/Loader'
import { Modal } from 'widgets/Modal'
import { useAccount } from 'wagmi'
import { Text } from 'components/Text'
import { Button } from 'components/Button'
import { useWeb3Modal } from '@web3modal/wagmi/react'
import { LinkExternal } from 'components/Link'
import { Flex } from 'components/Box'
import { RecentTransactionList, RecentTransactionWrap } from './styled'

type RecentTransactionsModalProps = {
  onDismiss?: () => void
}

// TODO: Fix UI Kit typings
const defaultOnDismiss = () => null

const newTransactionsFirst = (a: TransactionDetails, b: TransactionDetails) => b.addedTime - a.addedTime

const getRowStatus = (sortedRecentTransaction: TransactionDetails) => {
  const { hash, receipt } = sortedRecentTransaction

  if (!hash) {
    return { icon: <Loader />, color: 'text' }
  }

  if (hash && receipt?.status === 1) {
    return { icon: <CheckmarkCircleIcon color="success" />, color: 'success' }
  }

  return { icon: <ErrorIcon color="failure" />, color: 'failure' }
}

const RecentTransactionsModal = ({ onDismiss = defaultOnDismiss }: RecentTransactionsModalProps) => {
  const { address, chainId } = useAccount()
  const allTransactions = useAllTransactions()

  // Logic taken from Web3Status/index.tsx line 175
  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
        return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])

  const { open } = useWeb3Modal()

  return (
    <Modal title="Recent Transactions" onDismiss={onDismiss}>
      <FormMask className={!address ? 'Notconnect' : ''} style={{ minHeight: '250px', paddingTop: '20px' }}>
        {!address && (
          <Flex justifyContent="center" flexDirection="column" alignItems="center">
            <Text style={{ marginBottom: '0', fontSize: '20px', color: '#000' }} bold>
              Connect Wallet
            </Text>
            <Text style={{ marginBottom: '10px' }} bold>
              Connect your wallet to view your recent transactions
            </Text>
            <Button
              isNormal
              width="100%"
              variant="tertiary"
              size="sm"
              onClick={() => {
                open()
              }}
            >
              Connect Wallet
            </Button>
          </Flex>
        )}
        {address && chainId && sortedRecentTransactions.length === 0 && (
          <Flex justifyContent="center" flexDirection="column" alignItems="center">
            <figure>
              <img width={142} height={142} src="/images/gif/history_gif.gif" alt="gif" />
            </figure>
            <Text bold>No recent transactions</Text>
            <p>
              There’re no transaction.{' '}
              <Link
                to="/swap"
                onClick={() => onDismiss()}
                style={{ color: '#004CCD', textDecoration: 'underline', textUnderlineOffset: '2px' }}
              >
                Trade now?
              </Link>
            </p>
          </Flex>
        )}
        <RecentTransactionList>
          {address &&
            chainId &&
            sortedRecentTransactions.map((sortedRecentTransaction) => {
              const { hash, summary } = sortedRecentTransaction
              const { icon, color } = getRowStatus(sortedRecentTransaction)
              return (
                <RecentTransactionWrap>
                  <Flex
                    style={{ flexDirection: 'column', border: '1px solid #E5E7EB', padding: '10px' }}
                    key={hash}
                    alignItems="flex-start"
                    justifyContent="space-between"
                    mb="4px"
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <p style={{ fontSize: '14px', fontWeight: '300', color: '#717696' }}>View details</p>
                      <a href={`https://bscscan.com/tx/${hash}`} style={{ display: 'flex' }} target="_blank" rel="noreferrer">
                        <img src="./images/link.svg" alt="icon" />
                      </a>
                    </div>
                    <div style={{color: '#56557B', fontSize: '14px'}}>{summary ?? hash}</div>
                  </Flex>
                </RecentTransactionWrap>
              )
            })}
        </RecentTransactionList>
      </FormMask>
    </Modal>
  )
}

export default RecentTransactionsModal
