import styled from "styled-components";

export const TokenProgressList = styled.div`
  position: relative;
  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }
`
export const TokenProgressItem = styled.div`
  & > p {
    font-size: 14px;
    &:nth-child(1) {
      color: #0D0C43;
      font-family: Outfit-Medium;
      letter-spacing: 0.14px;
    }
    &:nth-child(2) {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 80px;
      color: #A3A3A3;
      font-size: 14px;
      border-radius: 29px;
      padding: 3px 8px;
    }
  }
  &:not(:last-child) {
    margin-bottom: 5px;
  }
`