import { useState } from 'react'
import { ethers } from 'ethers'
import Web3 from 'web3'
import toast from 'react-hot-toast'
import {
  ConfirmReviewBlock,
  ConfirmRowBlock,
  FormConfirmContainer,
  FormConfirmReview,
  FormConfirmRow,
  FormConfirmTokenName,
  ReviewLeft,
  ReviewRight,
} from './styled'
import { FormValueButtons } from '../FormValue/styled'
import { Button } from 'components/Button'
import checkInfoToken from 'utils/checkBalanceInfo'
import ABI from 'constants/CreateToken/abi/StandardTokenABI.json'
import { TOKEN_STANDARD_BYTECODE } from 'constants/CreateToken/bytecodeStandardToken'
import { FEE_DEPLOY, STANDAR_FEE_RECEIVE } from 'constants/CreateToken'
import { convertFixed, convertFromWei, convertToWei } from 'utils/convertNumber'
import { useAccount } from 'wagmi'
import { WORk_CHAIN } from 'constants/index'

declare const window: Window & typeof globalThis & { ethereum: any }

const FormConfirm = ({ setStateSwitch, tokenInfo, setTokenInfo }: any) => {
  const web3 = new Web3(window.ethereum)
  const { address } = useAccount()
  const [loadingConfirm, setLoadingConfirm] = useState(false)

  const deployToken = async () => {
    try {
      setLoadingConfirm(true)
      const provider = new ethers.providers.Web3Provider(window.ethereum)
      const signer = provider.getSigner()
      const Factory = new ethers.ContractFactory(ABI, TOKEN_STANDARD_BYTECODE, signer)
      const supplyToWei = await convertToWei(tokenInfo?.totalSupply, tokenInfo?.decimals)

      const balance = await web3.eth.getBalance(address || '')
      const balanceFromWei = await convertFromWei(balance, 18)

      const gasPrice = await web3.eth.getGasPrice()
      const block = await web3.eth.getBlock('latest')
      const length_block = block.transactions.length > 0 ? block.transactions.length : 1
      const gasEstimate = Number(block.gasLimit / length_block) * 3

      const gas_Price = web3.utils.toBN(gasPrice)
      const gas_Estimate = web3.utils.toBN(gasEstimate.toFixed())
      const FEE = web3.utils.toBN(web3.utils.toWei(FEE_DEPLOY(WORk_CHAIN)))
      const Total = gas_Price.mul(gas_Estimate).add(FEE)
      const Total_Fee = web3.utils.fromWei(Total.toString(), 'ether')

      if (Number(Total_Fee) <= Number(balanceFromWei)) {
        const contract = await Factory.deploy(
          tokenInfo?.tokenName,
          tokenInfo?.tokenSymbol,
          tokenInfo?.decimals.toString(),
          supplyToWei,
          STANDAR_FEE_RECEIVE,
          web3.utils.toWei(FEE_DEPLOY(WORk_CHAIN), 'ether'),
          { value: web3.utils.toWei(FEE_DEPLOY(WORk_CHAIN), 'ether') }
        )

        await contract.deployed().catch((err: any) => {
          console.error('err', err)
          setLoadingConfirm(false)
          toast.error(err)
          return
        })

        if (contract?.address) {
          const info = await checkInfoToken(contract.address, address)
          if (info?.address) {
            const newTokenInfo = { ...tokenInfo, addressCreated: info?.address }
            setTokenInfo(newTokenInfo)
            const storedTokens = JSON.parse(localStorage.getItem('createdTokens') || '[]');
            const updatedTokens = [...storedTokens, newTokenInfo];
            localStorage.setItem('createdTokens', JSON.stringify(updatedTokens));
            setStateSwitch(4)
            toast.success('Create New Token Successfully')
          }
        }
        setLoadingConfirm(false)
      } else {
        setLoadingConfirm(false)
        toast.error('Insufficient balance to cover the deployment fee.')
      }
    } catch (error: any) {
      console.error(error)
      setLoadingConfirm(false)
      toast.error(error?.data?.message || 'Create New Token Failed', { id: 'error' })
    }
  }

  return (
    <FormConfirmContainer>
      <FormConfirmTokenName>
        <p>Token Name</p>
        <p>{tokenInfo?.tokenName + ' - ' + tokenInfo?.tokenSymbol || '--'}</p>
      </FormConfirmTokenName>
      <FormConfirmRow>
        <ConfirmRowBlock>
          <p>Decimals</p>
          <p className="color">{tokenInfo?.decimals}</p>
        </ConfirmRowBlock>
        <ConfirmRowBlock>
          <p>Total Supply</p>
          <p className="color">{convertFixed(tokenInfo?.totalSupply)}</p>
        </ConfirmRowBlock>
        <ConfirmRowBlock>
          <p>Blockchain</p>
          <p className="bold">
            <img width={19} height={19} src="/images/addToken/bnb_icon.png" alt="icon" />
            Binance Smart Chain
          </p>
        </ConfirmRowBlock>
      </FormConfirmRow>
      <FormConfirmReview>
        <h2>Preview</h2>
        <ConfirmReviewBlock>
          <ReviewLeft>
            <p>{tokenInfo?.tokenName + ' - ' + tokenInfo?.tokenSymbol || '--'}</p>
            <p>Decimals: {tokenInfo?.decimals}</p>
          </ReviewLeft>
          <ReviewRight>
            <p>Total Supply</p>
            <p>{convertFixed(tokenInfo?.totalSupply)}</p>
          </ReviewRight>
        </ConfirmReviewBlock>
      </FormConfirmReview>
      <FormValueButtons>
        <Button
          style={{ width: '30%' }}
          onClick={() => {
            setStateSwitch(2)
          }}
        >
          Back
        </Button>
        <Button
          isNormal
          style={{ width: '70%' }}
          onClick={() => deployToken()}
          disabled={loadingConfirm ? true : false}
        >
          {loadingConfirm ? 'Loading...' : 'Create Token'}
        </Button>
      </FormValueButtons>
    </FormConfirmContainer>
  )
}

export default FormConfirm
