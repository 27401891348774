import { StyledPageHeader } from 'components/PageHeader'
import styled from 'styled-components'

export const CreateTokenForm = styled.div`
  height: 500px;
  ${StyledPageHeader} {
    margin-bottom: -10px;
  }
`
export const AddNoAddress = styled.div`
  margin-top: 40px;
  figure {
    width: 89px;
    height: 85px;
    margin: 0 auto 15px;
    img {
      width: 100%;
    }
  }
  & > p {
    color: #717696;
    text-align: center;
    font-size: 14px;
    letter-spacing: 0.14px;
    margin-bottom: 50px;
  }
`
