import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { AlertTriangle } from 'react-feather'
import { Text } from 'components/Text'
import { Button } from 'components/Button'
import { AutoColumn } from '../Column'
import { Wrapper, Section, BottomSection, ContentHeader } from './helpers'

type TransactionErrorContentProps = { message: string; onDismiss: () => void }

const TransactionErrorContent = ({ message, onDismiss }: TransactionErrorContentProps) => {
  const theme = useContext(ThemeContext)
  return (
    <Wrapper>
      <Section>
        <ContentHeader onDismiss={onDismiss}>Error</ContentHeader>
        <AutoColumn style={{ marginTop: 20, padding: '2rem 0 10px' }} gap="10px" justify="center">
          <AlertTriangle color='#ffd100' style={{ strokeWidth: 1.5 }} size={64} />
          <Text color="failure" style={{ textAlign: 'center', width: '85%', fontSize: '16px', color:'#0D0C43' }}>
            {message.includes('user rejected transaction') ? 'User rejected transaction' : message}
          </Text> 
        </AutoColumn>
      </Section>
      <BottomSection gap="12px">
        <Button isNormal onClick={onDismiss}>Dismiss</Button>
      </BottomSection>
    </Wrapper>
  )
}

export default TransactionErrorContent
