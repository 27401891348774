import React, { useState } from 'react';
import PageHeader from 'components/PageHeader';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import { Button } from 'components/Button';
import { FormMask } from 'Layout/styled';
import { BodyWrapper } from 'pages/AppBody';
import { AddNoAddress, CreateTokenForm } from './styled';
import FormConfirm from './FormConfirm';
import FormValue from './FormValue';
import FormComplete from './FormComplete';


const AddTokenForm = ({ stateSwitch, setStateSwitch }: any) => {
  const { open } = useWeb3Modal();

  const [tokenInfo, setTokenInfo] = useState<any>({
    tokenName: "",
    tokenSymbol: "",
    decimals: 18,
    totalSupply: 0,
    addressCreated: ""
  });

  const handleSwitchForm = () => {
    switch (stateSwitch) {
      case 2:
        return <FormValue setStateSwitch={setStateSwitch} setTokenInfo={setTokenInfo} tokenInfo={tokenInfo} />
      case 3:
        return <FormConfirm setStateSwitch={setStateSwitch} tokenInfo={tokenInfo} setTokenInfo={setTokenInfo} />
      case 4:
        return <FormComplete setStateSwitch={setStateSwitch} tokenInfo={tokenInfo} setTokenInfo={setTokenInfo} />
      default:
        return <AddNoAddress>
          <figure>
            <img src="/images/addToken/cw_addToken.png" alt="icon" />
          </figure>
          <p>
            Connect the wallet that you would like
            <br /> to add your new tokens to.
          </p>
          <Button
            isNormal
            style={{ width: '100%' }}
            onClick={() => open()}
          >
            Connect Wallet
          </Button>
        </AddNoAddress>
    }
  }

  const handleSwitchHeader = () => {
    switch (stateSwitch) {
      case 1:
        return <PageHeader title="Connect Wallet" description={`Create your own fully-audited token in 5 minutes`} />
      case 2:
        return <PageHeader title="Enter Token Info" description={`Please provide the following information`} />
      case 3:
        return <PageHeader title="Create Contract" description={`Please ensure the following details are correct`} />
      case 4:
        return <PageHeader title="Create Token Successfully" description={`You’re successfully create new token.`} />
      default:
        return <PageHeader title="--" description={`--`} />
    }
  }

  return (
    <CreateTokenForm>
      <BodyWrapper>
        {handleSwitchHeader()}
        <FormMask style={{
          paddingTop: '20px',
          paddingLeft: "40px",
          paddingRight: "40px",
          height: "88%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
          {handleSwitchForm()}
        </FormMask>
      </BodyWrapper>
    </CreateTokenForm>
  );
};

export default AddTokenForm;