import React, { useContext, useState } from 'react'
import { FromOverlayer } from 'Layout/styled'
import { CreateSubHeader } from 'pages/AddToken/styled'
import { TokenProgressItem, TokenProgressList } from './styled'
import { ContextProviderWrapper } from 'components/Context'

const CreateTokenProcess = ({ stateSwitch }: any) => {
  const { isMobile } = useContext(ContextProviderWrapper)!
  const [dataProgress, setDataProgress] = useState([
    {
      id: 1,
      status: 1,
      name: 'Connect Wallet',
    },
    {
      id: 2,
      status: 1,
      name: 'Enter Token Info',
    },
    {
      id: 3,
      status: 1,
      name: 'Create Contract',
    },
  ])

  return (
    <FromOverlayer style={{
      marginBottom: isMobile && '20px'
    }}>
      <CreateSubHeader>
        <h2>Creating Process</h2>
        <p className="token-progress">{stateSwitch === 1 ? "0%" : stateSwitch === 2 ? "33.33%" : stateSwitch === 3 ? "66.66%" : "100%"}</p>
      </CreateSubHeader>
      <TokenProgressList>
        {dataProgress.map((item) => {
          return (
            <TokenProgressItem key={item.id}>
              <p>{item.name}</p>
              <p style={{
                background: item.id < stateSwitch ? "#9AFFA4" : item.id === stateSwitch ? "#9AE1FF" : "#F3F3F3",
                color: item.id < stateSwitch ? "#00B834" : item.id === stateSwitch ? "#004493" : "#A3A3A3"
              }}>{item.id < stateSwitch ? "Completed" : item.id === stateSwitch ? "Processing" : "Next"}</p>
            </TokenProgressItem>
          )
        })}
      </TokenProgressList>
    </FromOverlayer>
  )
}

export default CreateTokenProcess
