import styled from "styled-components";

export const FormCompleteContainer = styled.div`
  width: 100%;
  figure {
    width: 144px;
    margin: 0 auto;
    img {
      width: 100%
    }
  }
`
export const FormCompleteWrapper = styled.div`
  max-width: 250px;
  margin: 0 auto 30px;
`
export const FormCompleteInfo = styled.div`
  & > p {
    color: #0D0C43;
    font-family: Outfit-Medium;
    font-size: 14px;
    text-align: center;
    margin-bottom: 5px;
  }
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    & > p {
      padding: 4px 10px;
      color: #00B834;
      font-size: 16px;
      border-radius: 29px;
      background: #C6FFCB;
    }
  }
`
export const CopyAddressComplete = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  border-radius: 29px;
  background: #67FF76;
  padding: 4px 12px;
  color: #000;
  font-size: 16px;
  cursor: pointer;
`