import React from 'react'
import { FormMask } from 'Layout/styled'
import { Wrapper, Section, BottomSection, ContentHeader } from './helpers'

type ConfirmationModalContentProps = {
  title: string
  onDismiss: () => void
  topContent: () => React.ReactNode
  bottomContent: () => React.ReactNode
}

const ConfirmationModalContent = ({ title, bottomContent, onDismiss, topContent }: ConfirmationModalContentProps) => {
  return (
    <Wrapper>
      <Section>
        <div style={{ marginBottom: '8px' }}>
          <ContentHeader onDismiss={onDismiss}>{title}</ContentHeader>
        </div>
        <FormMask
          style={{
            padding: '20px 20px 15px',
          }}
        >
          {topContent()}
          <BottomSection style={{ padding: 0 }} gap="12px">
            {bottomContent()}
          </BottomSection>
        </FormMask>
      </Section>
    </Wrapper>
  )
}

export default ConfirmationModalContent
