import React from 'react'
import { SyncAltIcon } from 'components/Svg'
import { Text } from 'components/Text'
import { Price } from '@tooldex/sdk'
import { convertFixed } from 'utils/convertNumber'
import { StyledBalanceMaxMini } from './styleds'

interface TradePriceProps {
  price?: Price
  showInverted: boolean
  setShowInverted: (showInverted: boolean) => void
}

export default function TradePrice({ price, showInverted, setShowInverted }: TradePriceProps) {
  const formattedPrice = !showInverted ? price?.toSignificant(6) : price?.invert()?.toSignificant(6)

  const show = Boolean(price?.baseCurrency && price?.quoteCurrency)
  const label = !showInverted

    ? `1 ${price?.baseCurrency?.symbol} = ${convertFixed(Number(formattedPrice))} ${price?.quoteCurrency?.symbol}`
    : `1 ${price?.quoteCurrency?.symbol} = ${convertFixed(Number(formattedPrice))} ${price?.baseCurrency?.symbol}`

  return (
    <Text fontSize="14px" style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', color: '#004CCD' }}>
      {show ? (
        <>
          {label}
          <StyledBalanceMaxMini style={{ marginLeft: '0' }} onClick={() => setShowInverted(!showInverted)}>
            <SyncAltIcon width="20px" color="primary" />
          </StyledBalanceMaxMini>
        </>
      ) : (
        '-'
      )}
    </Text>
  )
}
