import React from 'react'
import { FormMask } from 'Layout/styled'
import { Text } from 'components/Text'
import { AutoColumn } from '../Column'
import { Wrapper, Section, ConfirmedIcon, ContentHeader } from './helpers'

type ConfirmationPendingContentProps = { onDismiss: () => void; pendingText: string }

const ConfirmationPendingContent = ({ onDismiss, pendingText }: ConfirmationPendingContentProps) => {
  return (
    <Wrapper>
      <Section>
        <ContentHeader onDismiss={onDismiss}>Processing..</ContentHeader>
        <FormMask style={{marginTop: '5px'}}>
          <ConfirmedIcon style={{
            padding: "0 0 10px"
          }}>
            <img width={100} height={100} src="/images/gif/processing.gif" alt="loader" />
          </ConfirmedIcon>
          <AutoColumn gap="5px" justify="center">
            <AutoColumn gap="5px" justify="center">
              <Text fontSize="14px" style={{textAlign: 'center', color: '#0D0C43'}}>
                <strong>{pendingText}</strong>
              </Text>
            </AutoColumn>
            <Text fontSize="14px">Confirm this transaction in your wallet</Text>
          </AutoColumn>
        </FormMask>
      </Section>
    </Wrapper>
  )
}

export default ConfirmationPendingContent
