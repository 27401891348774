import styled from "styled-components";

export const AddTokenContainer = styled.div`
  max-width: 725px;
  width: 100%;
  margin-top: 130px;
  @media screen and (max-width: 767px) {
    margin-top: 30px;
  }
`
export const AddTokenWrapper = styled.div`
  display: flex;
  gap: 20px;
  @media screen and (max-width: 767px) {
    display: block;
  }
`
export const AddTokenMain = styled.div`
  max-width: 430px;
  width: 100%;
  flex-shrink: 0;
  @media screen and (max-width: 767px) {
    flex-shrink: unset;
    margin-bottom: 20px;
  }
`
export const AddTokenSub = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`
export const CreateSubHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 15px;
  h2 {
    color: #0D0C43;
    font-family: Outfit-Medium;
    font-size: 14px;
    letter-spacing: 0.14px;
  }
  p {
    font-family: Outfit-Semibold;
    font-size: 20px;
    color: #0D0C43;
    &.token-progress {
      color: #00CD46;
    }
  }
`